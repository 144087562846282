<template>
  <div class="tabs profile-tabs settings-tabs">
    <div class="tabs-nav">
      <div class="tabs-nav__item"><a class="tabs-nav__link" href="#">Offers</a></div>
      <div class="tabs-nav__item"><a class="tabs-nav__link" href="#">Blanks</a></div>
      <div class="tabs-nav__item"><a class="tabs-nav__link" href="#">Partners</a></div>
    </div>
    <div class="tabs-content profile-tabs__content settings-tabs__content">
      <div class="tabs-content__item">
        <div class="settings-wrapper">
          <p class="settings-wrapper__title"><span class="settings-wrapper__title-label">Open Offers</span>
          </p>
        </div>
        <div class="settings-tabs__content">
          <div class="trade-content__item">
            <div class="table table-offer swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide table__wrapper">
                  <div class="table-row table-row_header table-offer__row">
                    <div class="table-offer__column"></div>
                    <div class="table-offer__column">
                      <p class="table__title">offer</p>
                    </div>
                    <div class="table-offer__column">
                      <p class="table__title">price</p>
                    </div>
                    <div class="table-offer__column">
                      <p class="table__title">date/time</p>
                    </div>
                    <div class="table-offer__column">
                      <p class="table__title">conditions</p>
                    </div>
                    <div class="table-offer__column">
                      <p class="table__title">Payment</p>
                    </div>
                  </div>
                  <div class="table-row table-row_content table-offer__row">
                    <div class="table-offer__column table__column">
                      <p class="table__text">1/5</p>
                    </div>
                    <div class="table-offer__column table__column">
                      <p class="table__text"><span class="table__text-label">Buy BTC</span><span
                          class="table__icon">
                          <svg class="icon icon-arrow-down ">
                            <use xlink:href="/img/svg/sprite.svg#arrow-down"></use>
                          </svg></span></p>
                    </div>
                    <div class="table-offer__column table__column">
                      <p class="table__text"><span class="table__text-label">1 BTC = 54.5445 <br> Fix</span>
                      </p>
                    </div>
                    <div class="table-offer__column table__column">
                      <p class="table__text"><span class="table__text-label">12.12.2020 14:05 <br> 23.12.2022
                          15:20</span></p>
                    </div>
                    <div class="table-offer__column table__column table-offer__column_big">
                      <p class="table__text"><span class="table__icon">
                          <svg class="icon icon-clock ">
                            <use xlink:href="/img/svg/sprite.svg#clock"></use>
                          </svg></span><span class="table__text-label">2 h</span></p>
                      <p class="table__text"><span class="table__icon">
                          <svg class="icon icon-verify ">
                            <use xlink:href="/img/svg/sprite.svg#verify"></use>
                          </svg></span><span class="table__text-label">Medium</span></p>
                      <p class="table__text"><span class="table__icon">
                          <svg class="icon icon-contacts ">
                            <use xlink:href="/img/svg/sprite.svg#contacts"></use>
                          </svg></span></p>
                      <p class="table__text"><span class="table__icon">
                          <svg class="icon icon-passport ">
                            <use xlink:href="/img/svg/sprite.svg#passport"></use>
                          </svg></span></p>
                    </div>
                    <div class="table-offer__column table__column table-offer__column_big">
                      <ul class="table-payment">
                        <li class="table-payment__item"><img class="table-payment__img" src="img/skrill.svg">
                        </li>
                        <li class="table-payment__item"><img class="table-payment__img" src="img/paypal.svg">
                        </li>
                        <li class="table-payment__item"><img class="table-payment__img" src="img/swift.svg">
                        </li>
                        <li class="table-payment__item"><img class="table-payment__img" src="img/qiwi.svg">
                        </li>
                        <li class="table-payment__item"><img class="table-payment__img"
                            src="img/transferWise.svg"></li>
                        <li class="table-payment__item"><img class="table-payment__img"
                            src="img/payonner.svg"></li>
                      </ul>
                    </div>
                    <div class="table-offer__column table__column"><a class="table__link" href="#">Go to
                        Offer</a></div>
                    <div class="table-offer__column table__column"><a class="table__icon" href="#">
                        <svg class="icon icon-delete-red ">
                          <use xlink:href="/img/svg/sprite.svg#delete-red"></use>
                        </svg></a></div>
                  </div>
                  <div class="table-row table-row_content table-offer__row">
                    <div class="table-offer__column table__column">
                      <p class="table__text">1/5</p>
                    </div>
                    <div class="table-offer__column table__column">
                      <p class="table__text"><span class="table__text-label">Buy BTC</span><span
                          class="table__icon">
                          <svg class="icon icon-arrow-down ">
                            <use xlink:href="/img/svg/sprite.svg#arrow-down"></use>
                          </svg></span></p>
                    </div>
                    <div class="table-offer__column table__column">
                      <p class="table__text"><span class="table__text-label">1 BTC = 54.5445 <br> Fix</span>
                      </p>
                    </div>
                    <div class="table-offer__column table__column">
                      <p class="table__text"><span class="table__text-label">12.12.2020 14:05 <br> 23.12.2022
                          15:20</span></p>
                    </div>
                    <div class="table-offer__column table__column table-offer__column_big">
                      <p class="table__text"><span class="table__icon">
                          <svg class="icon icon-clock ">
                            <use xlink:href="/img/svg/sprite.svg#clock"></use>
                          </svg></span><span class="table__text-label">2 h</span></p>
                      <p class="table__text"><span class="table__icon">
                          <svg class="icon icon-verify ">
                            <use xlink:href="/img/svg/sprite.svg#verify"></use>
                          </svg></span><span class="table__text-label">Medium</span></p>
                      <p class="table__text"><span class="table__icon">
                          <svg class="icon icon-contacts ">
                            <use xlink:href="/img/svg/sprite.svg#contacts"></use>
                          </svg></span></p>
                    </div>
                    <div class="table-offer__column table__column table-offer__column_big">
                      <ul class="table-payment">
                        <li class="table-payment__item"><img class="table-payment__img" src="img/skrill.svg">
                        </li>
                        <li class="table-payment__item"><img class="table-payment__img" src="img/paypal.svg">
                        </li>
                        <li class="table-payment__item"><img class="table-payment__img" src="img/swift.svg">
                        </li>
                        <li class="table-payment__item"><img class="table-payment__img" src="img/qiwi.svg">
                        </li>
                      </ul>
                    </div>
                    <div class="table-offer__column table__column"><a class="table__link" href="#">Go to
                        Offer</a></div>
                    <div class="table-offer__column table__column"><a class="table__icon" href="#">
                        <svg class="icon icon-delete-red ">
                          <use xlink:href="/img/svg/sprite.svg#delete-red"></use>
                        </svg></a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-content__item">
        <div class="settings-wrapper">
          <div class="settings-wrapper__title"><span class="settings-wrapper__title-label">Blanks</span>
            <div class="new-link"><a class="new-link__wrapper popup-trigger" href="#"
                data-popup=".popup-add-blank"><span class="new-link__label">Add new Blank</span><span
                  class="new-link__icon">
                  <svg class="icon icon-add ">
                    <use xlink:href="/img/svg/sprite.svg#add"></use>
                  </svg></span></a></div>
          </div>
        </div>
        <div class="trade-content__item">
          <div class="blanks-wrapper">
            <div class="settings-tabs__content">
              <div class="blank-grid">
                <div class="blank-grid__item">
                  <div class="blank-item">
                    <div class="blank-item__wrapper">
                      <div class="blank-item__header">
                        <div class="blank-item__logo">
                          <div class="blank-item__logo-img"><img src="img/paypal.svg"></div>
                          <p class="blank-item__logo-label">PayPal</p>
                        </div>
                        <div class="blank-item__controls"><a class="blank-item__controls-item popup-trigger"
                            href="#" data-popup=".popup-edit-blank">
                            <svg class="icon icon-edit ">
                              <use xlink:href="/img/svg/sprite.svg#edit"></use>
                            </svg></a><a class="blank-item__controls-item" href="#">
                            <svg class="icon icon-delete-red ">
                              <use xlink:href="/img/svg/sprite.svg#delete-red"></use>
                            </svg></a></div>
                      </div>
                      <div class="blank-item__content">
                        <div class="blank-desc">
                          <p class="blank-desc__title">My Pal 1</p>
                          <p class="blank-desc__text">@chupa_dupa_trader</p>
                          <p class="blank-desc__text">@chupa_dupa_crypto</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blank-grid__item">
                  <div class="blank-item">
                    <div class="blank-item__wrapper">
                      <div class="blank-item__header">
                        <div class="blank-item__logo">
                          <div class="blank-item__logo-img"><img src="img/swift.svg"></div>
                          <p class="blank-item__logo-label">SWIFT</p>
                        </div>
                        <div class="blank-item__controls"><a class="blank-item__controls-item" href="#">
                            <svg class="icon icon-edit ">
                              <use xlink:href="/img/svg/sprite.svg#edit"></use>
                            </svg></a><a class="blank-item__controls-item" href="#">
                            <svg class="icon icon-delete-red ">
                              <use xlink:href="/img/svg/sprite.svg#delete-red"></use>
                            </svg></a></div>
                      </div>
                      <div class="blank-item__content">
                        <div class="blank-desc">
                          <p class="blank-desc__title">My Swift 2</p>
                          <p class="blank-desc__text">BANK OF BENEFICIARY:</p>
                          <p class="blank-desc__text">JOINT STOCK COMPANY 'ACCENT-BANK' DNEPROPETROVSK,</p>
                          <p class="blank-desc__text">UKRAINESWIFT CODE: UKCBUAUK</p>
                          <p class="blank-desc__text">CORRESPONDENT BANK:</p>
                          <p class="blank-desc__text">PJSC CB PRIVATBANK, DNEPROPETROVSK, UKRAINE SWIFT</p>
                          <p class="blank-desc__text">CODE: PBANUA2X</p>
                          <p class="blank-desc__text">CORRESPONDENT ACCOUNT: <br> 16008003064003</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="blank-grid__item">
                  <div class="blank-item">
                    <div class="blank-item__wrapper">
                      <div class="blank-item__header">
                        <div class="blank-item__logo">
                          <div class="blank-item__logo-img"><img src="img/qiwi.svg"></div>
                          <p class="blank-item__logo-label">QIWI</p>
                        </div>
                        <div class="blank-item__controls"><a class="blank-item__controls-item" href="#">
                            <svg class="icon icon-edit ">
                              <use xlink:href="/img/svg/sprite.svg#edit"></use>
                            </svg></a><a class="blank-item__controls-item" href="#">
                            <svg class="icon icon-delete-red ">
                              <use xlink:href="/img/svg/sprite.svg#delete-red"></use>
                            </svg></a></div>
                      </div>
                      <div class="blank-item__content">
                        <div class="blank-desc">
                          <p class="blank-desc__title">my Q</p>
                          <p class="blank-desc__text">Wallet 1: +14956789091</p>
                          <p class="blank-desc__text">Wallet 2: +14956709912</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-content__item">
        <div class="settings-wrapper">
          <p class="settings-wrapper__title"><span class="settings-wrapper__title-label">Trade Partners</span>
          </p>
        </div>
        <div class="settings-tabs__content">
          <div class="trade-content__item">
            <div class="table table-partners swiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide table__wrapper">
                  <div class="table-row table-row_header table-partners__row">
                    <div class="table-offer__column">
                      <p class="table__title">partner</p>
                    </div>
                    <div class="table-offer__column">
                      <p class="table__title">Deal</p>
                    </div>
                    <div class="table-offer__column">
                      <p class="table__title">id</p>
                    </div>
                    <div class="table-offer__column">
                      <p class="table__title">date/time</p>
                    </div>
                    <div class="table-offer__column">
                      <p class="table__title">status</p>
                    </div>
                    <div class="table-offer__column">
                      <p class="table__title">Offers</p>
                    </div>
                  </div>
                  <div class="table-row table-row_content table-partners__row">
                    <div class="table__column">
                      <p class="table__text"><span class="table__icon"><img
                            src="img/chat-profile.png"></span><span
                          class="table__text-label">Terminator_USA</span></p>
                    </div>
                    <div class="table__column">
                      <p class="table__text"><span class="table__text-label">Buy <span>0.0003328 BTC</span> /
                          Payment <span>1,230.00 USD</span></span></p>
                    </div>
                    <div class="table__column"><a class="table__link" href="#">1234567890</a></div>
                    <div class="table__column">
                      <p class="table__text"><span class="table__text-label">12.12.2020 14:05 <br> 23.12.2022
                          15:20</span></p>
                    </div>
                    <div class="table__column">
                      <p class="table__text"><span
                          class="table__text-label status status_succes">Succefully</span></p>
                    </div>
                    <div class="table__column"><a class="table__link" href="#">Show Offers</a></div>
                  </div>
                  <div class="table-row table-row_content table-partners__row">
                    <div class="table__column">
                      <p class="table__text"><span class="table__icon"><img
                            src="img/chat-profile.png"></span><span
                          class="table__text-label">Terminator_USA</span></p>
                    </div>
                    <div class="table__column">
                      <p class="table__text"><span class="table__text-label">Buy <span>0.0003328 BTC</span> /
                          Payment <span>1,230.00 USD</span></span></p>
                    </div>
                    <div class="table__column"><a class="table__link" href="#">1234567890</a></div>
                    <div class="table__column">
                      <p class="table__text"><span class="table__text-label">12.12.2020 14:05 <br> 23.12.2022
                          15:20</span></p>
                    </div>
                    <div class="table__column">
                      <p class="table__text"><span
                          class="table__text-label status status_failed">Canceled</span></p>
                    </div>
                    <div class="table__column"><a class="table__link" href="#">Show Offers</a></div>
                  </div>
                  <div class="table-row table-row_content table-partners__row">
                    <div class="table__column">
                      <p class="table__text"><span class="table__icon"><img
                            src="img/chat-profile.png"></span><span
                          class="table__text-label">Terminator_USA</span></p>
                    </div>
                    <div class="table__column">
                      <p class="table__text"><span class="table__text-label">Buy <span>0.0003328 BTC</span> /
                          Payment <span>1,230.00 USD</span></span></p>
                    </div>
                    <div class="table__column"><a class="table__link" href="#">1234567890</a></div>
                    <div class="table__column">
                      <p class="table__text"><span class="table__text-label">12.12.2020 14:05 <br> 23.12.2022
                          15:20</span></p>
                    </div>
                    <div class="table__column">
                      <p class="table__text"><span class="table__text-label status">Succefully.
                          Arbitrage</span></p>
                    </div>
                    <div class="table__column"><a class="table__link" href="#">Show Offers</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Trade',
  methods: {
    changeTab(tabBlock, idx = 0) {
      const tabBlockContents = tabBlock.querySelectorAll('.tabs-content__item');
      const tabBlockLink = tabBlock.querySelectorAll('.tabs-nav__item');

      tabBlockContents.forEach((el, index) => {
        if (index !== idx) {
          el.classList.remove('active');
        } else {
          el.classList.add('active');
        }
      });

      tabBlockLink.forEach((el, index) => {
        if (index !== idx) {
          el.querySelector('.tabs-nav__link').classList.remove('active');
        } else {
          el.querySelector('.tabs-nav__link').classList.add('active');
        }
      });
    },
  },
  mounted() {
    const tabsArr = document.querySelectorAll('.tabs');

    tabsArr.forEach((el) => {
      this.changeTab(el, 0);
    });

    document.querySelectorAll('.tabs-nav__link').forEach((el) => {
      el.addEventListener('click', (event) => {
        event.preventDefault();
        this.changeTab(
          el.closest('.tabs'),
          [...el.closest('.tabs').querySelectorAll('.tabs-nav__link')].indexOf(event.target),
        );
      });
    });
  },
};
</script>
