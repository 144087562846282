<template>
  <div
    class="page-content settings-content settings"
    v-bind:class="{ 'full': !$store.state.app.navigation, 'not-full': $store.state.app.navigation }"
  >
    <div class="page-content__wrapper">
      <div class="page-block">
        <div class="page-block__header settings__header">
         <p class="page-block__title">
            <router-link to="/">
              <a class="back-arrow">
                <img src="/img/white-arrow.svg">
              </a>
              {{ $t('views.settings.title') }}
            </router-link>
          </p>
          <div class="settings__header-block">
            <!-- <div class="custom-select custom-select_revert settings__header-select">
              <div class="custom-select__label">{{ $t('views.settings.main-currency') }}:</div>
              <select>
                <option>USD</option>
                <option>RUB</option>
              </select>
            </div> -->
            <!-- <a class="btn btn-blue settings__header-btn" href="#">{{ $t('views.settings.become-trader') }}</a> -->
          </div>
        </div>
        <div class="page-block__content">
          <div class="settings-content__header">
            <ul class="settings-list">
              <li
                class="settings-list__item"
                v-bind:class="{ 'settings-list__item_current':this.$route.name == 'Profile' }"
              >
                <a class="settings-list__link clickable" @click="routerPush('/settings/profile')">
                 {{ $t('views.settings.tab-profile') }}
                </a>
              </li>
              <li
                class="settings-list__item"
                v-bind:class="{ 'settings-list__item_current':this.$route.name == 'Messages' }"
              >
                <a class="settings-list__link clickable" @click="routerPush('/settings/messages')">
                  {{ $t('views.settings.tab-messages') }}
                </a>
                <span
                  class="notification-counter"
                  v-if="$store.state.app.notifications.length"
                >
                  {{ $store.state.app.notifications.length }}
                </span>
              </li>
              <!-- <li
                class="settings-list__item"
                v-bind:class="{ 'settings-list__item_current':this.$route.name == 'History' }"
              >
                <a class="settings-list__link clickable" @click="routerPush('/settings/history')">
                  {{ $t('views.settings.tab-history') }}
                </a>
                </li>
              <li
                class="settings-list__item"
                v-bind:class="{ 'settings-list__item_current':this.$route.name == 'History' }"
              >
                <a class="settings-list__link clickable" @click="routerPush('/settings/trade')">
                  {{ $t('views.settings.tab-trade') }}
                </a>
                </li> -->
            </ul>
          </div>
        </div>

        <router-view/>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Settings',
  methods: {

  },
  mounted() {

  },
};
</script>
