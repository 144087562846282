<template>
  <div class="tabs profile-tabs settings-tabs">
    <div class="tabs-nav">
      <div class="tabs-nav__item"><a class="tabs-nav__link" href="#">{{ $t('views.settings.profile.tab-personal') }}</a></div>
      <div class="tabs-nav__item"><a class="tabs-nav__link" href="#">{{ $t('views.settings.profile.tab-security') }}</a></div>
    </div>
    <div class="tabs-content profile-tabs__content settings-tabs__content">
      <div class="tabs-content__item">
        <div class="settings-wrapper">
          <div class="settings-wrapper__item">
            <div class="settings-wrapper__content">
              <div class="settings-grid">
                <div class="settings-grid__item">
                  <div class="profile-wrapper">
                    <div class="profile-block profile-wrapper__block">
                      <div class="profile-block__title">
                        <p class="profile-block__label">{{ $t('views.settings.profile.account-data') }}</p>
                      </div>
                      <div class="profile-block__wrapper">
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">{{ $t('views.settings.profile.login') }}</p>
                          <div class="profile-item__input-item">
                            <input
                              placeholder="machinegun@gmail.com"
                              disabled="disabled"
                              v-model="login"
                            />
                          </div>
                        </div>
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">{{ $t('views.settings.profile.icon') }}</p>
                          <div class="profile-item__input-item clickable" @click="$refs.icon.click()">
                            <div class="profile-item__icon-container">
                              <img :src="icon ? icon : '/img/profile.svg'" class="profile-item__icon">
                            </div>
                            <input
                              :value="
                                icon && icon !== '/img/profile.svg' ?
                                $t('views.settings.profile.file-loaded') :
                                $t('views.settings.profile.file-not-loaded')
                              "
                              class="clickable"
                              disabled
                            />
                            <input
                              type="file"
                              accept="image/png, image/jpeg"
                              ref="icon"
                              @change="iconHandle"
                              hidden
                            />
                          </div>
                        </div>
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">{{ $t('views.settings.profile.registration') }}</p>
                          <div class="profile-item__input-item">
                            <input
                              placeholder="12.12.2020"
                              v-model="register_date"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">{{ $t('views.settings.profile.last-visit') }}</p>
                          <div class="profile-item__input-item">
                            <input
                              v-model="last_visit"
                              disabled
                            />
                          </div>
                        </div>
                        <!-- <div class="profile-item__input">
                          <p class="profile-item__input-text">Nickname</p>
                          <div class="profile-item__input-item">
                            <input
                              placeholder=""
                              v-model="nickname"
                            />
                          </div>
                        </div> -->
                      </div>
                    </div>
                    <!-- <div class="profile-block profile-wrapper__block">
                      <div class="profile-block__title">
                        <p class="profile-block__label">{{ $t('views.settings.profile.personal-data') }}</p>
                        <div class="verify profile-block__verify  clickable">
                          <span class="verify__text">{{ $t('views.settings.profile.verify') }}</span>
                          <div class="verify__icon">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_537_2983)">
                                <path
                                  d="M23 12L20.56 9.07714L20.9 5.21143L17.29 4.35238L15.4 1L12 2.52952L8.6 1L6.71 4.34191L3.1 5.19048L3.44 9.06667L1 12L3.44 14.9229L3.1 18.799L6.71 19.6581L8.6 23L12 21.46L15.4 22.9895L17.29 19.6476L20.9 18.7886L20.56 14.9229L23 12ZM10.09 16.9448L6.29 12.9533L7.77 11.4029L10.09 13.8438L15.94 7.69429L17.42 9.24476L10.09 16.9448Z"
                                  fill="white"
                                  fill-opacity="0.2"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_537_2983">
                                  <rect
                                    width="22"
                                    height="22"
                                    fill="white"
                                    transform="translate(1 1)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="profile-block__wrapper">
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">{{ $t('views.settings.profile.first-name') }}</p>
                          <div class="profile-item__input-item">
                            <input v-model="firstName"/>
                          </div>
                        </div>
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">{{ $t('views.settings.profile.last-name') }}</p>
                          <div class="profile-item__input-item">
                            <input v-model="lastName"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="profile-block profile-wrapper__block">
                      <div class="profile-block__title">
                        <p class="profile-block__label">{{ $t('views.settings.profile.contacts') }}</p>
                        <div class="verify profile-block__verify clickable" verify_verified>
                          <span class="verify__text">Verify</span>
                          <div class="verify__icon">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_537_2983)">
                                <path
                                  d="M23 12L20.56 9.07714L20.9 5.21143L17.29 4.35238L15.4 1L12 2.52952L8.6 1L6.71 4.34191L3.1 5.19048L3.44 9.06667L1 12L3.44 14.9229L3.1 18.799L6.71 19.6581L8.6 23L12 21.46L15.4 22.9895L17.29 19.6476L20.9 18.7886L20.56 14.9229L23 12ZM10.09 16.9448L6.29 12.9533L7.77 11.4029L10.09 13.8438L15.94 7.69429L17.42 9.24476L10.09 16.9448Z"
                                  fill="white"
                                  fill-opacity="0.2"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_537_2983">
                                  <rect
                                    width="22"
                                    height="22"
                                    fill="white"
                                    transform="translate(1 1)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="profile-block__wrapper">
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">{{ $t('views.settings.profile.phone-number') }}</p>
                          <div class="profile-item__input-item">
                            <input v-model="phone"/>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                <!-- <div class="settings-grid__item">
                  <div class="profile-wrapper">
                    <div class="profile-block profile-wrapper__block">
                      <div class="profile-block__title">
                        <p class="profile-block__label">{{ $t('views.settings.profile.address') }}</p>
                      </div>
                      <div class="profile-block__wrapper">
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">{{ $t('views.settings.profile.country') }}</p>
                          <div class="profile-item__input-item">
                            <input
                              :placeholder="$t('views.settings.profile.country-ph')"
                              list="countries"
                              v-model="country"
                              class="profile-country__input"
                            />
                          <datalist id="countries">
                            <option
                              v-for="country in countries" :key="country.code"
                              :value="country.name"
                            />
                          </datalist>
                          </div>
                        </div>
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">{{ $t('views.settings.profile.city') }}</p>
                          <div class="profile-item__input-item">
                            <input
                              :placeholder="$t('views.settings.profile.city-ph')"
                              v-model="city"
                              />
                          </div>
                        </div>
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">{{ $t('views.settings.profile.address') }}</p>
                          <div class="profile-item__input-item">
                            <input
                            :placeholder="$t('views.settings.profile.address-ph')"
                            v-model="address"/>
                          </div>
                        </div>
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">{{ $t('views.settings.profile.zip') }}</p>
                          <div class="profile-item__input-item">
                            <input
                              :placeholder="$t('views.settings.profile.zip-ph')"
                              v-model="zip"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="profile-block profile-wrapper__block">
                      <div class="profile-block__title">
                        <p class="profile-block__label">{{ $t('views.settings.profile.documents-title') }}</p>
                      </div>
                      <div class="profile-block__wrapper">
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">{{ $t('views.settings.profile.passport') }}</p>
                          <div class="profile-item__input-item" @click="$refs.passport.click()">
                            <div class="profile-item__photo-container">
                              <img :src="passport" class="profile-item__photo">
                            </div>
                            <input
                              :value="
                                passport ? $t('views.settings.profile.file-loaded') : $t('views.settings.profile.file-not-loaded')
                              "
                              class="clickable"
                              disabled
                            />
                            <input
                              type="file"
                              accept="image/png, image/jpeg"
                              ref="passport"
                              @change="passportHandle"
                              hidden
                            />
                          </div>
                        </div>
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">{{ $t('views.settings.profile.selfie') }}</p>
                          <div class="profile-item__input-item" @click="$refs.selfie.click()">
                            <div class="profile-item__photo-container">
                              <img :src="selfie" class="profile-item__photo">
                            </div>
                            <input
                              :value="
                                selfie ? $t('views.settings.profile.file-loaded') : $t('views.settings.profile.file-not-loaded')
                              "
                              class="clickable"
                              disabled
                            />
                            <input
                              type="file"
                              accept="image/png, image/jpeg"
                              ref="selfie"
                              @change="selfieHandle"
                              hidden
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
                  <a
                    class="btn btn-blue settings-wrapper__btn"
                    v-bind:class="{ 'btn-disabled': !isDataChanged,'clickable':isDataChanged }"
                    v-if="!loading"
                    @click="saveProfile"
                  >
                    {{ $t('views.settings.profile.save') }}
                  </a>
                  <a
                    class="btn btn-yellow clickable settings-wrapper__btn"
                    v-if="!loading"
                    @click="disableProfile"
                  >
                    {{ $t('views.settings.profile.disable') }}
                  </a>
                </div>
              </div>
              <LoadingSpinner v-if="loading"/>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-content__item">
        <div class="settings-wrapper">
          <div class="settings-wrapper__item">
            <div class="settings-wrapper__content">
              <div class="settings-grid">
                <div class="settings-grid__item">
                  <div class="profile-wrapper">
                    <div class="profile-block profile-wrapper__block">
                      <div class="profile-block__wrapper">
                          <a
                            class="btn btn-blue settings-wrapper__btn clickable"
                            style="margin: 0 0 60px 0;"
                            @click="$store.dispatch('app/toggleChangePassword')"
                          >
                            Change password
                          </a>

                        <div class="profile-item__input">
                        </div>
                        <div class="profile-item__input">
                          <p class="profile-item__input-text">Last Visit</p>
                          <div class="profile-item__input-item">
                            <input placeholder="12.12.2020" v-model="last_visit" disabled/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="profile-block profile-wrapper__block">
                      <div class="profile-block__wrapper">
                        <div class="profile-item__input profile-item__input_radio">
                          <div class="radio-input">
                            <label class="radio-input__label">
                              <input class="radio-input__item" type="checkbox" disabled/><span
                                class="radio-input__text"
                                >2FA Google</span
                              ><span class="radio-input__checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div class="profile-item__input profile-item__input_radio">
                          <div class="radio-input">
                            <label class="radio-input__label">
                              <input class="radio-input__item" type="checkbox" disabled/><span
                                class="radio-input__text"
                                >2FA SMS</span
                              ><span class="radio-input__checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div class="profile-item__input profile-item__input_radio">
                          <div class="radio-input">
                            <label class="radio-input__label">
                              <input class="radio-input__item" type="checkbox" disabled/><span
                                class="radio-input__text"
                                >2FA Email</span
                              ><span class="radio-input__checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <!-- <a class="btn btn-blue settings-wrapper__btn" href="#">Save all Changes</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import countries from '@/plugins/countries.json';

export default {
  name: 'Profile',
  data() {
    return {
      login: this.$store.state.auth.email,
      register_date: moment(this.$store.state.profile.register_date).format('DD.MM.yyyy HH:mm'),
      // nickname: 'Нет в api',
      last_visit: moment(this.$store.state.profile.last_visit).format('DD.MM.yyyy HH:mm'),
      icon: this.$store.state.profile.icon,

      firstName: this.$store.state.profile.firstName,
      lastName: this.$store.state.profile.lastName,

      phone: this.$store.state.profile.phone,

      country: '',
      city: '',
      address: '',
      zip: '',

      passport: '',
      selfie: '',
      filesChanged: false,

      confirmed_personal: false,
      confirmed_contacts: false,
      loading: false,

      countries,
    };
  },
  methods: {
    changeTab(tabBlock, idx = 0) {
      const tabBlockContents = tabBlock.querySelectorAll('.tabs-content__item');
      const tabBlockLink = tabBlock.querySelectorAll('.tabs-nav__item');

      tabBlockContents.forEach((el, index) => {
        if (index !== idx) {
          el.classList.remove('active');
        } else {
          el.classList.add('active');
        }
      });

      tabBlockLink.forEach((el, index) => {
        if (index !== idx) {
          el.querySelector('.tabs-nav__link').classList.remove('active');
        } else {
          el.querySelector('.tabs-nav__link').classList.add('active');
        }
      });
    },
    getProfile() {
      this.loading = true;
      this.$http.get(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile`)
        .then((response) => {
          this.$store.dispatch('profile/setProfile', response.data);
          this.fillProfileData(response.data);
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            console.log(error);
          }
        }).then(() => {
          this.loading = false;
        });
    },
    fillProfileData(data) {
      console.log(this.login);
      this.firstName = data.verificationLevelOne.firstName;
      this.lastName = data.verificationLevelOne.lastName;
      this.phone = data.verificationLevelOne.phone;
      this.confirmed_personal = data.verificationLevelOne.confirmed;

      this.country = data.location.country;
      this.city = data.location.city;
      this.address = data.location.address;
      this.zip = data.location.zipCode;

      this.last_visit = moment(data.lastVisit).format('DD.MM.yyyy HH:mm');
      // this.last_visit = moment(data.lastVisit).format('DD.MM.yyyy HH:mm');
      this.register_date = moment(data.created).format('DD.MM.yyyy HH:mm');
      // console.log(data.icon);
      this.icon = data.icon;

      this.passport = data.verificationLevelTwo.passport;
      this.selfie = data.verificationLevelTwo.selfie;

      this.icon = this.$store.state.profile.icon;
    },
    saveProfile() {
      this.loading = true;
      if (!this.isDataChanged) {
        return false;
      }

      const data = {
        username: this.nickname,
        icon: this.icon,
        verificationLevelOne: {
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
        },
        location: {
          country: this.country,
          city: this.city,
          address: this.address,
          zipCode: this.zip,
        },
        verificationLevelTwo: {
          passport: this.passport,
          selfie: this.selfie,
        },
      };

      this.$http.put(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile`, data)
        .then(() => {
          this.$root.snackbar.info(this.$i18n.t('response-messages.success.success'));
          this.getProfile();
          // console.log(response);
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            console.log(error);
          }
        })
        .then(() => {
          this.loading = false;
        });
      return true;
    },
    disableProfile() {
      const popupData = {
        show: true,
        title: '',
        text: `<div style="text-align: center; font-size: 130%; font-weight: 500; line-height: initial;">
          <span>Are you sure you want to delete your account?</span><br/><span>The consequences can be irreversible!</span>
        </div>`,
        /* eslint-disable max-len */
        icon: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTA4IiBoZWlnaHQ9IjEwOCIgdmlld0JveD0iMCAwIDEwOCAxMDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxjaXJjbGUgY3g9IjU0IiBjeT0iNTQiIHI9IjUxLjM2OTYiIGZpbGw9IiNGRjMzMzMiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iNS4yNjA3NSIvPgo8cGF0aCBkPSJNMzIuOTE5NSAyNi4wNDEyTDI1Ljk2ODQgMzIuOTkyM0w0Ni44MjE2IDUzLjg0NTVMMjUuOTY4NCA3NC42OTg2TDMyLjkxOTUgODEuNjQ5N0w1My43NzI3IDYwLjc5NjVMNzQuNjI1OSA4MS42NDk3TDgxLjU3NjkgNzQuNjk4Nkw2MC43MjM3IDUzLjg0NTVMODEuNTc2OSAzMi45OTIzTDc0LjYyNTkgMjYuMDQxMkw1My43NzI3IDQ2Ljg5NDRMMzIuOTE5NSAyNi4wNDEyWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==',
      };
      popupData.buttons = [{
        text: 'Delete Account',
        class: 'btn-yellow',
        action: () => {
          this.$store.dispatch('app/toggleUniPopup');
          this.disableProfileDo();
          this.logout();
        },
      }];
      this.$store.dispatch('app/setUniPopup', popupData);
    },
    disableProfileDo() {
      const data = {
        enabled: false,
      };

      this.$http.put(`${process.env.VUE_APP_PUBLIC_PREFIX}/profile`, data)
        .then(() => {
          this.$root.snackbar.info(this.$i18n.t('response-messages.success.success'));
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
            console.log(error);
          }
        })
        .then(() => {
          this.loading = false;
        });
      return true;
    },
    passportHandle(event) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.passport = e.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
      this.filesChanged = true;
    },
    selfieHandle(event) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.selfie = e.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
      this.filesChanged = true;
    },
    iconHandle(event) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.icon = e.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
      this.filesChanged = true;
    },
    resetPassword() {},
  },
  components: {
    LoadingSpinner,
  },
  mounted() {
    this.getProfile();

    const tabsArr = document.querySelectorAll('.tabs');

    tabsArr.forEach((el) => {
      this.changeTab(el, 0);
    });

    document.querySelectorAll('.tabs-nav__link').forEach((el) => {
      el.addEventListener('click', (event) => {
        event.preventDefault();
        this.changeTab(
          el.closest('.tabs'),
          [...el.closest('.tabs').querySelectorAll('.tabs-nav__link')].indexOf(event.target),
        );
      });
    });
  },
  computed: {
    isDataChanged() {
      if (
        this.firstName === this.$store.state.profile.firstName
        && this.lastName === this.$store.state.profile.lastName
        && this.phone === this.$store.state.profile.phone
        && this.country === this.$store.state.profile.country
        && this.city === this.$store.state.profile.city
        && this.address === this.$store.state.profile.address
        && this.zip === this.$store.state.profile.zip
        && !this.filesChanged
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>
