<template>
  <div class="tabs profile-tabs settings-tabs">
    <div class="tabs-header">
      <div class="tabs-nav">
        <div class="tabs-nav__item"><a class="tabs-nav__link" href="#">System</a></div>
        <div class="tabs-nav__item"><a class="tabs-nav__link" href="#">Admins</a></div>
      </div>
      <div class="tabs-header__content">
        <div class="sort clickable" @click="setAllAsRead()">
          <div class="sort-item">
            <div class="sort-item__icon">
              <svg class="icon icon-readed ">
                <use xlink:href="/img/svg/sprite.svg#readed"></use>
              </svg>
            </div>
            <span class="sort-item__label">Set all as Read</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-content profile-tabs__content settings-tabs__content">
      <div class="tabs-content__item">
        <div class="settings-wrapper">
          <div class="settings-wrapper__item">
            <div class="settings-wrapper__content">
              <div class="notification-block">
                <div
                  class="notification-block__item"
                  v-for="(notif, key) in $store.state.app.notifications" :key="notif.date"
                >
                  <div
                    class="notification-item clickable"
                    v-if="notif.type == 'WALLET'"
                    @click="toggleNotification(notif, key)"
                  >
                    <div class="notification-item__wrapper notification-item__wrapper_dark">
                      <div class="notification-item__date">
                        {{ toNormalDate(notif.date) }}
                        <p class="notification-item__title">New deposit</p>
                        <p class="notification-item__text">
                          {{ $t('components.notifications.new-deposit-text', [notif.infoSmall.currency]) }}.
                          (<b>{{ notif.infoSmall.value }} {{ notif.infoSmall.currency }}</b>)
                        </p>
                        <p class="notification-item__text" v-if="notif.active">
                          <span v-for="(value, key) in notif.infoSmall" :key="key">
                            {{ key.toUpperCase() }}:
                            <a
                              v-if="
                                key === 'hash'
                                && (getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'ETH'
                                  || getBlockchainInfo(notif.infoSmall.currency).typeCryptoCurrency == 'TOKEN'
                                  || notif.infoSmall.currency == 'ETH')
                              "
                              :href="'https://etherscan.io/tx/0x' + notif.infoSmall.hash"
                              style="text-decoration: underline;"
                              target="_blank"
                            >
                              {{ value }}
                            </a>
                            <a
                              v-else-if="key === 'hash' && (
                                getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'BTC' || notif.infoSmall.currency == 'BTC'
                              )"
                              :href="'https://btc.bitaps.com/' + notif.infoSmall.hash"
                              style="text-decoration: underline;"
                              target="_blank"
                            >
                              {{ value }}
                            </a>
                            <a
                              v-else-if="key === 'hash' && (
                                getBlockchainInfo(notif.infoSmall.currency) == 'LTC' || notif.infoSmall.currency == 'LTC'
                              )"
                              :href="'https://ltc.bitaps.com/' + notif.infoSmall.hash"
                              style="text-decoration: underline;"
                              target="_blank"
                            >
                              {{ value }}
                            </a>
                            <a
                              v-else-if="
                                key === 'hash' && (
                                getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'BCY'
                                || notif.infoSmall.currency == 'BCY')
                              "
                              :href="'https://api.blockcypher.com/v1/bcy/test/txs/' + notif.infoSmall.hash"
                              style="text-decoration: underline;"
                              target="_blank"
                            >
                              {{ value }}
                            </a>
                            <a
                              v-else-if="
                                key === 'hash' && (
                                getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'BETH'
                                || notif.infoSmall.currency == 'BETH')
                              "
                              :href="'https://api.blockcypher.com/v1/beth/test/txs/' + notif.infoSmall.hash"
                              style="text-decoration: underline;"
                              target="_blank"
                            >
                              {{ value }}
                            </a>
                            <a
                              v-else-if="
                                getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'DOGE'
                                || notif.infoSmall.currency == 'DOGE'
                              "
                              :href="'https://blockchair.com/dogecoin/transaction/' + notif.infoSmall.hash"
                              style="text-decoration: underline;"
                              target="_blank"
                            >
                              {{ value }}
                            </a>
                            <a
                              v-else-if="
                                getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'DASH'
                                || notif.infoSmall.currency == 'DASH'
                              "
                              :href="'https://blockchair.com/dash/transaction/' + notif.infoSmall.hash"
                              style="text-decoration: underline;"
                              target="_blank"
                            >
                              {{ value }}
                            </a>
                            <a
                              v-else
                            >
                              {{ value }}
                            </a>
                            <br/>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-content__item">
        <div class="settings-wrapper">
          <div class="settings-wrapper__item">
            <div class="settings-wrapper__content">
              <div class="notification-block">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Messages',
  methods: {
    changeTab(tabBlock, idx = 0) {
      const tabBlockContents = tabBlock.querySelectorAll('.tabs-content__item');
      const tabBlockLink = tabBlock.querySelectorAll('.tabs-nav__item');

      tabBlockContents.forEach((el, index) => {
        if (index !== idx) {
          el.classList.remove('active');
        } else {
          el.classList.add('active');
        }
      });

      tabBlockLink.forEach((el, index) => {
        if (index !== idx) {
          el.querySelector('.tabs-nav__link').classList.remove('active');
        } else {
          el.querySelector('.tabs-nav__link').classList.add('active');
        }
      });
    },
    toNormalDate(datetime) {
      return moment(datetime).format('DD.MM.yyyy HH:mm');
    },
    toggleNotification(notif, key) {
      this.$store.dispatch('app/toggleNotification', key);
      this.setAsRead(notif);
    },
    setAsRead(notif) {
      this.$http.patch(`${process.env.VUE_APP_PUBLIC_PREFIX}/messages/${notif.id}`)
        .then(() => {
          console.log('Прочитано');
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
          }
        });
    },
    setAllAsRead() {
      this.$http.patch(`${process.env.VUE_APP_PUBLIC_PREFIX}/messages/entities`)
        .then(() => {
          this.$root.snackbar.info(this.$i18n.t('response-messages.success.success'));
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.wrong-token'));
                this.logout();
                break;
              default:
                this.$root.snackbar.warn(this.$i18n.t('response-messages.error.unexpected-error'));
            }
          }
        });
    },
  },
  mounted() {
    const tabsArr = document.querySelectorAll('.tabs');

    tabsArr.forEach((el) => {
      this.changeTab(el, 0);
    });

    document.querySelectorAll('.tabs-nav__link').forEach((el) => {
      el.addEventListener('click', (event) => {
        event.preventDefault();
        this.changeTab(
          el.closest('.tabs'),
          [...el.closest('.tabs').querySelectorAll('.tabs-nav__link')].indexOf(event.target),
        );
      });
    });
  },
};
</script>
