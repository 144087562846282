var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs profile-tabs settings-tabs"},[_c('div',{staticClass:"tabs-header"},[_vm._m(0),_c('div',{staticClass:"tabs-header__content"},[_c('div',{staticClass:"sort clickable",on:{"click":function($event){return _vm.setAllAsRead()}}},[_c('div',{staticClass:"sort-item"},[_c('div',{staticClass:"sort-item__icon"},[_c('svg',{staticClass:"icon icon-readed "},[_c('use',{attrs:{"xlink:href":"/img/svg/sprite.svg#readed"}})])]),_c('span',{staticClass:"sort-item__label"},[_vm._v("Set all as Read")])])])])]),_c('div',{staticClass:"tabs-content profile-tabs__content settings-tabs__content"},[_c('div',{staticClass:"tabs-content__item"},[_c('div',{staticClass:"settings-wrapper"},[_c('div',{staticClass:"settings-wrapper__item"},[_c('div',{staticClass:"settings-wrapper__content"},[_c('div',{staticClass:"notification-block"},_vm._l((_vm.$store.state.app.notifications),function(notif,key){return _c('div',{key:notif.date,staticClass:"notification-block__item"},[(notif.type == 'WALLET')?_c('div',{staticClass:"notification-item clickable",on:{"click":function($event){return _vm.toggleNotification(notif, key)}}},[_c('div',{staticClass:"notification-item__wrapper notification-item__wrapper_dark"},[_c('div',{staticClass:"notification-item__date"},[_vm._v(" "+_vm._s(_vm.toNormalDate(notif.date))+" "),_c('p',{staticClass:"notification-item__title"},[_vm._v("New deposit")]),_c('p',{staticClass:"notification-item__text"},[_vm._v(" "+_vm._s(_vm.$t('components.notifications.new-deposit-text', [notif.infoSmall.currency]))+". ("),_c('b',[_vm._v(_vm._s(notif.infoSmall.value)+" "+_vm._s(notif.infoSmall.currency))]),_vm._v(") ")]),(notif.active)?_c('p',{staticClass:"notification-item__text"},_vm._l((notif.infoSmall),function(value,key){return _c('span',{key:key},[_vm._v(" "+_vm._s(key.toUpperCase())+": "),(
                              key === 'hash'
                              && (_vm.getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'ETH'
                                || _vm.getBlockchainInfo(notif.infoSmall.currency).typeCryptoCurrency == 'TOKEN'
                                || notif.infoSmall.currency == 'ETH')
                            )?_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":'https://etherscan.io/tx/0x' + notif.infoSmall.hash,"target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")]):(key === 'hash' && (
                              _vm.getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'BTC' || notif.infoSmall.currency == 'BTC'
                            ))?_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":'https://btc.bitaps.com/' + notif.infoSmall.hash,"target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")]):(key === 'hash' && (
                              _vm.getBlockchainInfo(notif.infoSmall.currency) == 'LTC' || notif.infoSmall.currency == 'LTC'
                            ))?_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":'https://ltc.bitaps.com/' + notif.infoSmall.hash,"target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")]):(
                              key === 'hash' && (
                              _vm.getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'BCY'
                              || notif.infoSmall.currency == 'BCY')
                            )?_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":'https://api.blockcypher.com/v1/bcy/test/txs/' + notif.infoSmall.hash,"target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")]):(
                              key === 'hash' && (
                              _vm.getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'BETH'
                              || notif.infoSmall.currency == 'BETH')
                            )?_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":'https://api.blockcypher.com/v1/beth/test/txs/' + notif.infoSmall.hash,"target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")]):(
                              _vm.getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'DOGE'
                              || notif.infoSmall.currency == 'DOGE'
                            )?_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":'https://blockchair.com/dogecoin/transaction/' + notif.infoSmall.hash,"target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")]):(
                              _vm.getBlockchainInfo(notif.infoSmall.currency).basicCurrency == 'DASH'
                              || notif.infoSmall.currency == 'DASH'
                            )?_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":'https://blockchair.com/dash/transaction/' + notif.infoSmall.hash,"target":"_blank"}},[_vm._v(" "+_vm._s(value)+" ")]):_c('a',[_vm._v(" "+_vm._s(value)+" ")]),_c('br')])}),0):_vm._e()])])]):_vm._e()])}),0)])])])]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs-nav"},[_c('div',{staticClass:"tabs-nav__item"},[_c('a',{staticClass:"tabs-nav__link",attrs:{"href":"#"}},[_vm._v("System")])]),_c('div',{staticClass:"tabs-nav__item"},[_c('a',{staticClass:"tabs-nav__link",attrs:{"href":"#"}},[_vm._v("Admins")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs-content__item"},[_c('div',{staticClass:"settings-wrapper"},[_c('div',{staticClass:"settings-wrapper__item"},[_c('div',{staticClass:"settings-wrapper__content"},[_c('div',{staticClass:"notification-block"})])])])])}]

export { render, staticRenderFns }